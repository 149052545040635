import Link from '@/components/Link';
import { PrimaryTaxonomyItemLink } from '@gik/blog/components/PrimaryTaxonomyItemLink/PrimaryTaxonomyItemLink';
import type Article from '@gik/blog/models/Article';
import type { Layout } from '@gik/core/types/UI';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import { BackgroundImage } from '@gik/ui/BackgroundImage';
import { Button } from '@gik/ui/Button';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { KeepProportions } from '@gik/ui/KeepProportions';
import { FrameBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import { SvgIcon } from '@gik/ui/SvgIcon';
import type { UISize, UIVariant } from '@gik/ui/types';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { ArticleAuthorLink } from '../ArticleAuthorLink';

export const blogEndpoint = 'articles';

interface IBaseArticleTileProps extends React.HTMLAttributes<HTMLDivElement> {
  article?: Article;
  showCategory?: boolean;
  showDescription?: boolean;
  showAuthor?: boolean;
  skeleton?: boolean;
  layout?: Layout;
  hero?: boolean;
  header?: boolean;
  noLink?: boolean;
  size?: UISize;
  noClamping?: boolean;
  onClickRemove?(product: Article): void;
}

const skelBem = bemBlock(`article-tile-skeleton`);
const Skeleton = ({
  showCategory,
  showDescription,
  showAuthor,
  hero,
  layout = 'auto',
}: Partial<IBaseArticleTileProps>): React.ReactElement => (
  <div className={`${skelBem(null, [{ [`layout-${layout}`]: true }, { hero }])}`}>
    <KeepProportions proportion={layout != 'row' ? 0.72 : 1}>
      <div className={skelBem('image-wrapper')}>
        <FrameBone className={skelBem('image')} />
      </div>
    </KeepProportions>
    <main>
      {showCategory && <ParagraphBone words={2} className={skelBem('category')} />}
      <ParagraphBone words={6} className={skelBem('title')} />
      {showAuthor && <ParagraphBone words={20} className={skelBem('author-link')} />}
      {showDescription && <ParagraphBone words={20} className={skelBem('description')} />}
    </main>
  </div>
);

const blockName = 'base-article-tile';
function BaseArticleTile({
  article,
  showCategory,
  showDescription,
  showAuthor,
  skeleton,
  className,
  layout = 'auto',
  hero = false,
  header = false,
  noLink = false,
  size = 'base',
  noClamping = false,
  onClickRemove,
}: IBaseArticleTileProps): React.ReactElement {
  const bem = bemBlock(blockName);
  const articleLinkUrl = article && `/${blogEndpoint}/${article.articleSlug}`;

  const [largeThumb, setLargeThumb] = React.useState<boolean>(false);
  const resizeObserver = React.useRef<ResizeObserver>(null);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (ref.current && !resizeObserver.current) {
      resizeObserver.current = new ResizeObserver(entries => {
        const entry = entries[0];
        setLargeThumb(entry?.contentRect?.width > 320);
      });
      resizeObserver.current.observe(ref.current);
    }

    return () => {
      resizeObserver.current?.disconnect();
    };
  }, []);

  const articleImage = React.useMemo(() => {
    const bem = bemBlock(blockName);

    const baseImage = (
      <BackgroundImage
        src={hero || largeThumb ? article?.largeImageUrl : article?.smallImageUrl}
        className={bem('image')}
      />
    );

    const image = <KeepProportions proportion={layout != 'row' || header ? 0.72 : 1}>{baseImage}</KeepProportions>;

    if (noLink || !articleLinkUrl) {
      return <section className={bem('image-wrapper')}>{image}</section>;
    } else {
      return (
        <Link href={articleLinkUrl} scroll className={bem('image-wrapper')}>
          {image}
        </Link>
      );
    }
  }, [article?.largeImageUrl, article?.smallImageUrl, articleLinkUrl, header, hero, largeThumb, noLink, layout]);

  const articleTitle = React.useMemo(() => {
    if (article?.isPreviewOnly) {
      return (
        <>
          <span className="tw-text-danger-500">Preview </span> {article?.title}
        </>
      );
    } else {
      return <>{article?.title}</>;
    }
  }, [article]);

  function handleClickRemove(article: Article, e?: React.MouseEvent) {
    e?.preventDefault();
    e?.stopPropagation();

    onClickRemove?.(article);
  }

  if (skeleton) {
    return (
      <Skeleton
        showCategory={showCategory}
        showDescription={showDescription}
        showAuthor={showAuthor}
        layout={layout}
        hero={hero}
      />
    );
  }

  return (
    <div
      ref={ref}
      className={bem(
        null,
        [
          { [`layout-${layout}`]: true },
          { hero },
          { header },
          { [`size-${size}`]: size },
          { 'no-clamping': noClamping },
          { 'no-link': noLink },
          { 'no-image': !article?.largeImageUrl && !article?.smallImageUrl },
        ],
        className
      )}
    >
      {articleImage}
      <main>
        {showCategory && <PrimaryTaxonomyItemLink className={bem('category')} article={article} />}

        <div className={bem('title')}>
          {noLink ? (
            <div className={bem('title')}>{articleTitle}</div>
          ) : (
            <Link href={articleLinkUrl} scroll>
              <div className={bem('title')}>{articleTitle}</div>
            </Link>
          )}
        </div>

        {showDescription && <div className={bem('description')}>{HTMLParser({ rawHtml: article?.excerpt })}</div>}
        {showAuthor && (
          <ArticleAuthorLink
            authorFullName={article?.authorFullName}
            authorSlug={article?.authorSlug}
            articleId={article?.articleId}
            className={bem('author-link')}
          />
        )}
        {!skeleton && onClickRemove && (
          <div className={bem('remove-button')}>
            <Button
              circle
              variant={'danger-plain'}
              size={'sm'}
              onClick={e => handleClickRemove(article, e)}
              tooltip="Remove article"
            >
              {<SvgIcon size="sm" Icon={TrashIcon} />}
            </Button>
          </div>
        )}
      </main>
    </div>
  );
}

ArticleTile.requiredWPFields = [
  'slug',
  'acf.thumb.sizes.post-thumbnail',
  'acf.thumb.sizes.woocommerce_thumbnail',
  'acf.preview_mode',
  'primary-taxonomy-meta.taxonomy',
  'primary-taxonomy-meta.slug',
  'title.rendered',
  'excerpt.rendered',
  'blog-author.name',
  'blog-author.slug',
  'id',
];

export type IArticleTileProps = Omit<IBaseArticleTileProps, 'showCategory' | 'size'>;
export function ArticleTile({ className, ...otherProps }: IArticleTileProps) {
  const bem = useBemCN('article-tile');
  return <BaseArticleTile showCategory {...bem(null, null, className)} {...otherProps} />;
}

export type IArticleCarouselTileProps = Omit<
  IBaseArticleTileProps,
  'largeThumb' | 'showCategory' | 'noClamping' | 'size' | 'row'
>;
export function ArticleCarouselTile({ className, ...otherProps }: IArticleCarouselTileProps) {
  const bem = useBemCN('article-carousel-tile');
  return <BaseArticleTile showCategory noClamping layout={'column'} {...bem(null, null, className)} {...otherProps} />;
}

export type IArticleHeroProps = Omit<IBaseArticleTileProps, 'hero' | 'showCategory' | 'noClamping' | 'size'> & {
  variant?: UIVariant;
};
export function ArticleHero({ className, variant = 'primary', ...otherProps }: IArticleHeroProps) {
  const bem = useBemCN('article-hero');
  return (
    <BaseArticleTile
      hero
      showCategory
      noClamping
      {...bem(null, [{ [`variant-${variant}`]: variant }], className)}
      {...otherProps}
    />
  );
}

export type IArticleHeaderProps = Omit<
  IBaseArticleTileProps,
  'header' | 'showCategory' | 'noClamping' | 'largeThumb' | 'size'
>;
export function ArticleHeader({ className, ...otherProps }: IArticleTileProps) {
  const bem = useBemCN('article-header');
  return <BaseArticleTile header noLink showCategory noClamping {...bem(null, null, className)} {...otherProps} />;
}
