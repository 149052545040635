import Link from '@/components/Link';
import { BlogAddToFavoritesButton } from '@gik/blog/components/BlogAddToFavoritesButton/BlogAddToFavoritesButton';
import routes from '@gik/core/routes';
import bemBlock from '@gik/core/utils/bemBlock';
import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
interface ArticleAuthorLinkProps extends React.HTMLAttributes<HTMLDivElement> {
  authorFullName: string;
  authorSlug: string;
  articleId: string;
  date?: string;
}

export function ArticleAuthorLink({
  authorFullName,
  authorSlug,
  articleId,
  date,
  className,
}: ArticleAuthorLinkProps): React.ReactElement {
  const bem = bemBlock('author-link');

  const child = <span className="author">BY {authorFullName?.toUpperCase()}</span>;
  const authorContainer = authorSlug ? (
    <Link href={`${routes.blog}/authors/${authorSlug}`} scroll>
      {child}
    </Link>
  ) : (
    child
  );

  const dateContainer = (
    <>
      {date && (
        <>
          <span className="separator">•</span>
          <time className="date" dateTime={moment(date).format()} title={moment(date).format('MMM DD YYYY')}>
            {moment(date).format('MMM DD')}
          </time>
        </>
      )}
    </>
  );

  const blockClasses = classnames(bem(), className || '');
  return (
    <div className={blockClasses}>
      <BlogAddToFavoritesButton type="articles" typeId={articleId} />
      {authorContainer}
      {dateContainer}
    </div>
  );
}
