import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { useApi } from '@gik/core/api/swr/useApi';
import { apiCap } from '..';

const path = 'users';

function getParams() {
  return {
    type: 'products',
    perPage: apiCap,
  };
}

export function useSavedProductIds(userId?: string, config?: SWRConfigInterface) {
  return useApi<string[]>(userId ? `${path}/${userId}/favorites` : null, getParams(), config);
}
