import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';

export interface IBackgroundImageProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * url to image
   * This is optional because the url to the image could be fetched from the backend.
   * TODO: In that case this component should display itself in a loading state.
   */
  src?: string;
}

const blockName = `${gikClassPrefix}-bg-image`;

/**
 * This component displays an image as a css background
 */
function BackgroundImageComp({ children, src, className, ...otherProps }: IBackgroundImageProps): React.ReactElement {
  const blockClasses = classnames([blockName, className || '']);

  const style = src
    ? {
        backgroundImage: `url(${src})`,
      }
    : {};

  // FIXME: fix base components inheritance
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div className={blockClasses} style={style} {...(otherProps as any)}>
      {children}
    </div>
  );
}

// only re-render image if the src changed
export const BackgroundImage = React.memo(BackgroundImageComp, (prevProps, nextProps) => {
  return prevProps?.src === nextProps?.src;
});
