import { useBemCN } from '@gik/core/utils/bemBlock';
import { AddToFavouritesButton } from '@gik/ui/gik/AddToFavouritesButton';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import HeartOutlineIcon from '@heroicons/react/outline/HeartIcon';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import React from 'react';

export interface IBlogAddToFavoritesButtonProps {
  type: 'articles' | 'products';
  typeId: string;
}

const blockName = 'blog-add-to-favorites-button';

export function BlogAddToFavoritesButton({ type, typeId }: IBlogAddToFavoritesButtonProps) {
  const bem = useBemCN(blockName);

  return (
    <AddToFavouritesButton
      type={type}
      itemId={typeId}
      child={(onClick, _isLoading, isFavorited) => (
        <div {...bem()}>
          <div {...bem(null, [{ favorited: isFavorited }])} onClick={onClick}>
            <SvgIcon key={isFavorited ? 1 : 0} Icon={isFavorited ? HeartIcon : HeartOutlineIcon} />
          </div>
          <span {...bem('separator')}>•</span>
        </div>
      )}
    />
  );
}
