import Link from '@/components/Link';
import type Article from '@gik/blog/models/Article';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export interface IPrimaryTaxonomyItemLinkProps {
  article: Article;
  hidden?: boolean;
  className?: string;
}

const blockName = 'primary-taxonomy-item-link';

export const blogEndpoint = 'articles';

export enum BlogSections {
  generalResults = 'general-results',
  authors = 'authors',
  home = 'home',
}

export function PrimaryTaxonomyItemLink({ article, hidden, className }: IPrimaryTaxonomyItemLinkProps) {
  const bem = useBemCN(blockName);

  return article?.taxonomyItemSlug ? (
    <Link
      href={`/${blogEndpoint}/${BlogSections.generalResults}/${article?.primaryTaxonomySlug}/${article?.taxonomyItemSlug}`}
      shallow
      {...bem(null, [{ hidden }], className)}
    >
      <span>{article?.taxonomyItemName}</span>
    </Link>
  ) : null;
}
