import { useSavedArticleIds } from '@gik/api/users/savedArticles';
import { useSavedProductIds } from '@gik/api/users/savedProducts';
import { auth } from '@gik/auth';
import { optimisticUpdate } from '@gik/core/api';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useUserStore } from '@gik/core/store/UserStore';
import { UI } from '@gik/ui/UIManager';
import React from 'react';

interface AddToFavouritesButtonProps {
  type: 'articles' | 'products';
  itemId: string;
  child(
    onClick: (ev: React.MouseEvent<HTMLElement>) => void,
    isLoading: boolean,
    isFavourited: boolean
  ): React.ReactElement;
}

function addToFavorite(userId: string, itemId: string, type: string) {
  return dotnetApi.post(`users/${userId}/favorites`, {
    body: JSON.stringify({
      item_id: itemId,
      type: type,
    }),
  });
}

function removeFromFavorite(userId: string, itemId: string, type: string) {
  return dotnetApi.delete(`users/${userId}/favorites/${itemId}`, {
    searchParams: {
      type,
    },
  });
}

function AddToFavouritesButtonComp({ type, itemId, child }: AddToFavouritesButtonProps): React.ReactElement {
  const userStore = useUserStore();
  const userId = userStore.id;

  // TODO: should just retrieve the favourite by item ID
  const useHook = type === 'articles' ? useSavedArticleIds : useSavedProductIds;
  const { data: favouriteIds, isValidating: isLoading, mutate: reloadFavourites } = useHook(userId);

  const onClick = React.useCallback(async () => {
    if (!userId) {
      auth.signin();
      return;
    }
    // optimistic update
    const isFavourited = favouriteIds && favouriteIds.indexOf(itemId) >= 0;
    const newData = isFavourited ? favouriteIds.filter(id => id !== itemId) : [...favouriteIds, itemId];

    const fn = async () => {
      if (!isFavourited) {
        try {
          await addToFavorite(userId, itemId, type);
          UI.notifySuccess('Item successfully added to favorites');
        } catch (err) {
          UI.notifyError('Failed to add item to favorites');
        }
      } else {
        try {
          await removeFromFavorite(userId, itemId, type);
          UI.notifySuccess('Item successfully removed from favorites');
        } catch (err) {
          UI.notifyError('Failed to remove item from favorites');
        }
      }
    };

    await optimisticUpdate(newData, null, reloadFavourites, fn);
  }, [favouriteIds, itemId, reloadFavourites, type, userId]);

  const isFavourited = favouriteIds && favouriteIds.indexOf(itemId) >= 0;
  return child(onClick, isLoading, isFavourited);
}

export const AddToFavouritesButton = React.memo(AddToFavouritesButtonComp, () => true);
